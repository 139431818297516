<template>
  <div class="footer">
    <b-button
      pill
      variant="outline-light"
      size="lg"
      class="facebook-link my-2 my-sm-0"
      href="https://www.facebook.com/Praxis-f%C3%BCr-Physiotherapie-Schwarz-364543734505159"
    >
      <b-icon-facebook class="mr-2" />Wir sind auf Facebook!
    </b-button>
    <p class="copyright">
      <span>Andreas Schwarz © 2020-{{ new Date().getFullYear() }}</span>
      <br class="d-block d-sm-none" /><span class="d-none d-sm-inline">
        –
      </span>
      <span>Alle Rechte vorbehalten.</span>
    </p>
    <p class="text-links">
      <router-link to="/legal">Impressum</router-link>
      <br class="d-block d-sm-none" /><span class="d-none d-sm-inline">
        –
      </span>
      <router-link to="/oss">Open Source Komponenten</router-link>
    </p>
  </div>
</template>
<script>
import { BIconFacebook } from "bootstrap-vue";
import { version } from "../../package.json";

export default {
  name: "FooterComponent",
  components: {
    BIconFacebook,
  },
  data() {
    return {
      version: version,
    };
  },
};
</script>
<style lang="scss" scoped>
#footer {
  height: 7.5vh;
}

.copyright {
  margin-bottom: 0;
}

.text-links > a {
  color: var(--secondary);
}
</style>
