<template>
  <div class="oss content-margin">
    <h1>Benutzte Open Source Bibliotheken</h1>
    <table class="table table-sm table-striped">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Version</th>
        <th scope="col">Copyright</th>
        <th scope="col">Lizenz</th>
      </tr>
      <tr v-for="license in licenses" :key="license.name">
        <td>
          <a :href="license.repository">{{ license.name }}</a>
        </td>
        <td>{{ license.version }}</td>
        <td>{{ license.copyright }}</td>
        <td>{{ license.licenses }}</td>
      </tr>
    </table>
    <div
      class="license-text"
      v-for="license in Object.keys(licenseTexts)"
      :key="license"
    >
      <h2>{{ license }}</h2>
      <p>{{ licenseTexts[license] }}</p>
    </div>
  </div>
</template>
<script>
import licenses from "@/assets/oss.json";
import { version } from "../../package.json";

delete licenses[`as-web@${version}`]; // remove the web app itself

export default {
  name: "OSS",
  data() {
    return {
      licenses: licenses,
    };
  },
  computed: {
    licenseTexts() {
      const licenseTexts = {};
      Object.keys(licenses).forEach((element) => {
        if (!(licenses[element].licenses in licenseTexts)) {
          licenseTexts[licenses[element].licenses] =
            licenses[element].licenseText;
        }
      });
      return licenseTexts;
    },
  },
};
</script>
<style lang="scss" scoped>
h1,
h2 {
  text-align: center;
}
.oss {
  text-align: justify;
  a {
    color: var(--app-color);
  }
  table {
    width: 100%;
    overflow-x: scroll;
    @media screen and (max-width: 819px) {
      white-space: nowrap;
      display: flow-root;
    }
  }
  th {
    background-color: var(--primary);
  }
  tr {
    height: 40px;
  }
}
.license-text {
  margin-top: 35px;
}
</style>
