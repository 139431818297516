<template>
  <b-carousel
    id="carousel"
    v-model="slide"
    :interval="5000"
    controls
    indicators
  >
    <b-carousel-slide
      v-for="(item, index) in slides"
      :key="index"
      :caption="item.caption"
    >
      <template v-slot:img>
        <img
          class="slide-img"
          :src="item.source"
          :alt="item.caption"
          width="100%"
        />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
export default {
  name: "PraxisComponent",
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      slide: 0,
      slides: [
        { caption: "Willkommen", source: require("@/assets/Willkommen.jpg") },
        {
          caption: "Physiopraxis Bodenkirchen",
          source: require("@/assets/PhysiopraxisBodenkirchen.jpg"),
        },
        {
          caption: "Behandlungsraum 1",
          source: require("@/assets/PhysiopraxisBodenkirchenBehandlung1.jpg"),
        },
        {
          caption: "Behandlungsraum 2",
          source: require("@/assets/PhysiopraxisBodenkirchenBehandlung2.jpg"),
        },
        {
          caption: "Gymnastikraum",
          source: require("@/assets/PhysiopraxisBodenkirchenGymnastikraum.jpg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.carousel {
  background: repeating-radial-gradient(transparent, #57af38);
  text-shadow: 1px 1px 2px #333;
  .slide-img {
    @media screen and (min-aspect-ratio: 1/1) {
      width: auto;
      height: calc(100vh - 158px);
    }
    @media screen and (max-aspect-ratio: 1/1) {
      width: 100vw;
      height: auto;
    }
  }
}
</style>
