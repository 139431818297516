<template>
  <b-navbar id="app-nav" toggleable="lg" type="light">
    <b-navbar-brand to="/">
      <img
        class="brand-element"
        src="@/assets/logo.png"
        alt="Praxis for Physiotherapie Andreas Schwarz"
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Left aligned navbar items -->
      <b-navbar-nav>
        <b-nav-item to="/praxis">Praxis</b-nav-item>
        <b-nav-item to="/leistungen">Leistungen</b-nav-item>
        <b-nav-item to="/team">Team</b-nav-item>
        <b-nav-item id="job" to="/stellenangebote" disabled
          >Stellenangebote</b-nav-item
        >
      </b-navbar-nav>

      <!-- Right aligned navbar items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-button
            pill
            variant="outline-light"
            size="lg"
            class="facebook-link my-2 my-sm-0"
            href="https://www.facebook.com/Praxis-f%C3%BCr-Physiotherapie-Schwarz-364543734505159"
          >
            <b-icon-facebook class="mr-2" />Wir sind auf Facebook!
          </b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import { BIconFacebook } from "bootstrap-vue";

export default {
  name: "NavigationComponent",
  components: {
    BIconFacebook,
  },
};
</script>
<style lang="scss">
#app-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
}
.brand-element {
  border-radius: 9px 3px;
  margin-top: 4px;
  margin-bottom: 3px;
  height: 40px;
}
a.btn.facebook-link {
  color: #4267b2 !important;

  &:hover {
    background-color: lightgray !important;
  }
}

.pulse {
  overflow: visible;
  position: relative;
}
.pulse:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary);
  border-radius: inherit;
  transition: opacity 0.3s, transform 0.3s;
  animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  animation-play-state: inherit;
  z-index: -1;
}
@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 0;
    transform: scale(1.5);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
</style>
