import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueRouter from "vue-router";
import App from "./App.vue";
import Team from "./components/Team.vue";
// import Job from "./components/Job.vue";
import Legal from "./components/Legal.vue";
import OSS from "./components/OSS.vue";
import Home from "./components/Home.vue";
import Services from "./components/Services.vue";
import Praxis from "./components/Praxis.vue";

Vue.config.productionTip = false;

// Use bootstrap
Vue.use(BootstrapVue);

// Routing
Vue.use(VueRouter);
const routes = [
  { path: "/", component: Home },
  { path: "/leistungen", component: Services },
  { path: "/praxis", component: Praxis },
  { path: "/team", component: Team },
  // { path: "/stellenangebote", component: Job },
  { path: "/legal", component: Legal },
  { path: "/oss", component: OSS },
  { path: "*", redirect: "/" }
];
const router = new VueRouter({
  routes
});

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
