<template>
  <div id="content">
    <h1 class="headline">Das Team</h1>
    <img
      class="team-img"
      src="@/assets/team.jpg"
      alt="Praxis für Physiotherapie Schwarz"
    />
    <h1 class="headline">Unsere Mitarbeiter</h1>
    <b-card
      no-body
      class="overflow-hidden team-card"
      v-for="teamMember in team"
      :key="teamMember.name"
    >
      <b-row no-gutters>
        <b-col md="6">
          <b-card-img
            v-if="teamMember.picture"
            :src="require('@/assets/' + teamMember.picture)"
            :alt="teamMember.name"
            class="rounded-0"
          ></b-card-img>
          <b-card-img
            v-else
            :src="require('@/assets/no_profile.png')"
            :alt="teamMember.name"
            class="rounded-0"
          ></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body
            :title="teamMember.name"
            :sub-title="teamMember.jobs.join(' - ')"
          >
            <b-card-text>
              <ul class="upper p-0">
                <li v-for="(skill, index) in teamMember.skills" :key="index">
                  {{ skill }}
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import team from "@/assets/team.json";

export default {
  name: "TeamComponent",
  data() {
    return {
      picture: team.picture,
      team: team.members,
    };
  },
};
</script>
<style lang="scss" scoped>
#content {
  color: black;
}

.headline {
  background-color: var(--primary);
  color: #000000b0;
  font-weight: 800;

  @media screen and (max-width: 459px) {
    font-size: 40px;
  }
  @media screen and (min-width: 460px) {
    font-size: 54px;
  }
}

.team-img {
  border-radius: 0.25rem;
  margin: 3rem 3rem 6rem 3rem;
  max-width: 80%;
  box-shadow: 3px 3px 5px 0 var(--app-background-color-inverted);
}

.team-card {
  background-color: white;
  display: inline-block;
  box-shadow: 3px 3px 5px 0 var(--app-background-color-inverted);
  margin: 30px;

  .card-title,
  .card-subtitle {
    font-weight: bolder;
  }

  .card-img {
    background-color: var(--app-background-color);
  }

  ul {
    list-style-type: none;
    text-align: left;
    display: inline-block;
  }

  @media screen and (max-width: 575px) {
    width: calc(100% - 60px);
  }
  @media screen and (min-width: 576px) {
    width: 70%;
    max-width: 1023px;
  }
}
</style>
