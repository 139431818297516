<template>
  <div>
    <b-modal
      size="xl"
      ref="notdienst-modal"
      title="Physiotherapeuten Notdienst"
      header-bg-variant="info"
      centered
      scrollable
      hide-footer
    >
      <img class="modal-content" src="@/assets/notdienst.jpg" alt="Notdienst" />
    </b-modal>
    <img
      class="logo"
      src="@/assets/logo.png"
      alt="Praxis für Physiotherapie Andreas Schwarz"
    />
    <div class="footer inverted">
      <p>
        Harpoldener Straße 8a<br class="d-block d-sm-none" /><span
          class="d-none d-sm-inline"
        >
          • </span
        >84155 Bodenkirchen
      </p>
      <p>
        Tel: +49 (0) 8745 965 84 51<br class="d-block d-sm-none" /><span
          class="d-none d-sm-inline"
        >
          • </span
        >Fax: +49 (0) 8745 965 84 52
      </p>
      <a href="mailto:info@physiotherapieschwarz.de"
        >info@physiotherapieschwarz.de</a
      >
      <br /><br />
      <a class="website" href="www.physiotherapieschwarz.de"
        >www.physiotherapieschwarz.de</a
      >
      <br />
      <iframe
        class="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d662.4642590078419!2d12.391099298809552!3d48.382482504390914!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47758dd1a0d01aed%3A0x5239b939803f1703!2sPraxis%20f%C3%BCr%20Physiotherapie%20Schwarz!5e0!3m2!1sen!2sde!4v1614518125321!5m2!1sen!2sde"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </div>
    <div class="lower mt-2 mb-2">
      <h1>Terminevereinbarung ist telefonisch möglich!</h1>
      <b-table-simple class="mt-2 mb-3 align-center" borderless small>
        <b-tbody>
          <b-tr
            ><b-th class="text-right">Montag</b-th
            ><b-td class="text-left">8-12 Uhr und 14-19 Uhr</b-td></b-tr
          >
          <b-tr
            ><b-th class="text-right">Dienstag</b-th
            ><b-td class="text-left">8-12 Uhr und 14-18 Uhr</b-td></b-tr
          >
          <b-tr
            ><b-th class="text-right">Mittwoch</b-th
            ><b-td class="text-left">8-12 Uhr</b-td></b-tr
          >
          <b-tr
            ><b-th class="text-right">Donnerstag</b-th
            ><b-td class="text-left">8-12 Uhr und 14-19 Uhr</b-td></b-tr
          >
          <b-tr
            ><b-th class="text-right">Freitag</b-th
            ><b-td class="text-left">8-12 Uhr</b-td></b-tr
          >
          <b-tr
            ><b-th class="text-right">Samstag</b-th
            ><b-td class="text-left">Geschlossen</b-td></b-tr
          >
          <b-tr
            ><b-th class="text-right">Sonntag</b-th
            ><b-td class="text-left">Geschlossen</b-td></b-tr
          >
        </b-tbody>
      </b-table-simple>
      <h1><b-icon-telephone-fill /> +49 (0) 8745 965 84 51</h1>
    </div>
  </div>
</template>
<script>
import { BModal, BIconTelephoneFill, BTableSimple } from "bootstrap-vue";

export default {
  name: "HomeComponent",
  components: {
    BModal,
    BIconTelephoneFill,
    BTableSimple,
  },
  methods: {
    showModal: function () {
      this.$refs["notdienst-modal"].show();
    },
  },
};
</script>
<style lang="scss" scoped>
p,
li,
a,
.upper > h3,
a.website {
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
  @media screen and (min-width: 576px) {
    font-size: 30px;
  }
  font-weight: 500;
}

.inverted {
  background-color: var(--primary);
  color: var(--app-color-inverted);
  a {
    color: white;
  }
}

.headline {
  h1 {
    @media screen and (max-width: 299px) {
      font-size: 40px;
    }
    @media screen and (min-width: 300px) and (max-width: 575px) {
      font-size: 47px;
    }
    @media screen and (min-width: 576px) {
      font-size: 76px;
    }
    font-weight: 800;
    span {
      color: black;
    }
  }
  h2 {
    @media screen and (max-width: 299px) {
      font-size: 20px;
    }
    @media screen and (min-width: 300px) and (max-width: 575px) {
      font-size: 22px;
    }
    @media screen and (min-width: 576px) {
      font-size: 38px;
    }
    font-weight: 600;
  }
}

.logo {
  @media screen and (max-width: 575px) {
    margin: 30px;
    width: calc(100% - 60px);
  }
  @media screen and (min-width: 576px) {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 70%;
    max-width: 600px;
  }
}

.footer {
  padding-top: 30px;
  line-height: 1;
  p,
  a {
    @media screen and (max-width: 299px) {
      font-size: 19px;
    }
    @media screen and (min-width: 300px) and (max-width: 575px) {
      font-size: 20px;
    }
    @media screen and (min-width: 576px) {
      font-size: 24px;
    }
  }
  a.website {
    @media screen and (max-width: 299px) {
      font-size: 19px;
    }
    @media screen and (min-width: 300px) and (max-width: 575px) {
      font-size: 22px;
    }
    @media screen and (min-width: 576px) {
      font-size: 32px;
    }
    color: black;
  }
  .map {
    border: 0;
    margin: 15px;
    // @media screen and (max-width: 299px) {
    //   font-size: 19px;
    // }
    @media screen and (max-width: 575px) {
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
      height: 75vw;
    }
    @media screen and (min-width: 576px) {
      width: 70%;
      height: 40vw;
    }
  }
}

.upper > ul {
  margin-top: 50px;
  margin-bottom: 50px;
  list-style-type: none;
  @media screen and (max-width: 575px) {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0px;
  }
}
.lower {
  table {
    display: inline-table;
    margin-right: auto;
    width: fit-content;
  }
  p {
    line-height: 1;
    &.phone {
      @media screen and (max-width: 299px) {
        font-size: 34px;
      }
      @media screen and (min-width: 300px) and (max-width: 575px) {
        font-size: 40px;
      }
      @media screen and (min-width: 576px) {
        font-size: 56px;
      }
    }
  }
}
</style>
