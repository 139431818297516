<template>
  <div class="inverted">
    <div class="headline contrast">
      <h1>Unsere Leistungen</h1>
    </div>
    <ul class="upper p-0">
      <li>Klassische Massagetherapie (KMT)</li>
      <li>Manuelle Lymphdrainage (MLD)</li>
      <li>Krankengymnastik (KG)</li>
      <li>Manuelle Therapie (MT)</li>
      <li>Elektrotherapie (ET)</li>
      <li>Elektrostimulation (EST)</li>
      <li>Fango (FA)</li>
      <li>Heißluft (HL)</li>
      <li>Heiße Rolle (HR)</li>
      <li>Ultraschall (US)</li>
      <li>Kältetherapie</li>
    </ul>
    <p class="contrast">
      Alle Leistungen auch für Selbstzahler auf Basis des sektoralen
      Heilpraktiker
    </p>
  </div>
</template>

<script>
export default {
  name: "ServicesComponent",
};
</script>

<style lang="scss" scoped>
p,
li,
a {
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
  @media screen and (min-width: 576px) {
    font-size: 30px;
  }
  font-weight: 500;
}

ul {
  list-style-type: none;
  text-align: left;
  display: inline-block;
}

.inverted {
  background-color: var(--primary);
  color: var(--app-color-inverted);
}

.contrast {
  color: #000000b0;
}

.headline > h1 {
  @media screen and (max-width: 299px) {
    font-size: 40px;
  }
  @media screen and (min-width: 300px) and (max-width: 575px) {
    font-size: 47px;
  }
  @media screen and (min-width: 576px) {
    font-size: 76px;
  }
  font-weight: 800;
}
</style>
