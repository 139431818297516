<template>
  <div id="app">
    <Navigation />
    <router-view id="content"></router-view>
    <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Nav";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="scss">
@import "assets/custom.scss";
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  #content {
    margin-top: 73px;
    margin-bottom: 20px;
  }
}
</style>
